import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import electrode from '../images/electrode.png';
import Water from './water';

const animateDuration = 34 * 1000;
const timePoints: Record<string, number> = {
  powerUnitText: 0,
  powerUnitBg: 0.5,
  leftBar: 1.2,
  rightBar: 1.2,
  powerUnitLeftBg: 2,
  powerUnitRightBg: 2,
  powerUnitRightImage: 3.2,
  powerUnitLeftText: 3.5,
  powerUnitRightText: 3.5,
  leftContainerText: 5,
  metalBucket: 5.5,
  metalBucketTop: 5.5,
  metalBucketBottom: 5.5,
  metalBucketText: 6.5,
  rightContainerText: 7.8,
  waterBucket: 8.3,
  waterBucketTop: 8.3,
  waterBucketBottom: 8.3,
  waterBucketText: 9.3,
  hidePowerUnitText: 12.0,
  hideLeftContainerText: 12.0,
  hideRightContainerText: 12.0,
  leftOutLine: 13.0,
  leftInnerLine: 13.6,
  rightOutLine: 15.0,
  rightInnerLine: 15.6,
  leftPipeArrow: 18,
  showLeftWater: 19,
  rightPipeArrow: 21,
  showRightWater: 22,
  largeUpArrow: 25,
  largeUpArrowText: 26.5,
  largeDownArrow: 27,
  largeDownArrowText: 27.5,
};

// Object.keys(timePoints).forEach(key => {
//   timePoints[key] *= 1;
// });

const duration: Record<string, number> = {
  powerUnitText: 0.7,
  powerUnitBg: 1,
  leftBar: 0.5,
  rightBar: 0.5,
  powerUnitLeftBg: 0.8,
  powerUnitRightBg: 0.8,
  powerUnitRightImage: 1,
  powerUnitLeftText: 0.7,
  powerUnitRightText: 0.7,
  leftContainerText: 0.7,
  metalBucket: 1,
  metalBucketTop: 1,
  metalBucketBottom: 1,
  metalBucketText: 0.7,
  rightContainerText: 0.7,
  waterBucket: 1,
  waterBucketTop: 1,
  waterBucketBottom: 1,
  waterBucketText: 0.7,
  leftOutLine: 1,
  leftInnerLine: 1,
  rightOutLine: 1,
  rightInnerLine: 1,
  leftPipeArrow: 1,
  rightPipeArrow: 1,
  largeUpArrow: 1,
  largeDownArrow: 1,
  largeUpArrowText: 0.7,
  largeDownArrowText: 0.7,
};

// Object.keys(duration).forEach(key => {
//   duration[key] *= 1;
// });

function Battery({ replay }: { replay: () => void}) {
  const [hideCenterText, setHideCenterText] = useState(false);
  const [hideLeftText, setHideLeftText] = useState(false);
  const [hideRightText, setHideRightText] = useState(false);
  const [showRightWater, setShowRightWater] = useState(false);
  const [showLeftWater, setShowLeftWater] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHideCenterText(true);
    }, timePoints.hidePowerUnitText * 1000);
    setTimeout(() => {
      setHideLeftText(true);
    }, timePoints.hideLeftContainerText * 1000);
    setTimeout(() => {
      setHideRightText(true);
    }, timePoints.hideRightContainerText * 1000);
    setTimeout(() => {
      setShowLeftWater(true);
    }, timePoints.showLeftWater * 1000);
    setTimeout(() => {
      setShowRightWater(true);
    }, timePoints.showRightWater * 1000);

    setTimeout(() => {
      replay();
    }, animateDuration);
  }, [])

  return (<>
    {showLeftWater && <Water width={248} height={346} x={36} y={51} leftOrRight={true} />}
    {/* 左外圈 */}
    <motion.rect width="248" height="346" x="35" y="50" rx="20" stroke="#37acbf" strokeWidth={2} fill="transparent"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.3) -2px -2px 2px)"}}
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ delay: timePoints.leftOutLine, duration: duration.leftOutLine }}
    />
    {/* 左内圈 */}
    <motion.rect width="204" height="302" x="57" y="72" rx="8" stroke="#37acbf" strokeWidth={2} fill="transparent"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.3) -2px -2px 2px)"}}
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ delay: timePoints.leftInnerLine, duration: duration.leftInnerLine }}
    />
    {/* 上箭头 */}
    <motion.polygon points="140,62 148,66 148,58" fill="#37acbf"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.leftPipeArrow, duration: duration.leftPipeArrow }}
    />
    <motion.line x1="147" y1="62" x2="182" y2="62" stroke="#37acbf" strokeWidth={1}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.leftPipeArrow, duration: duration.leftPipeArrow }}
    />
    {/* 下箭头 */}
    <motion.polygon points="191,386 183,390 183,382" fill="#37acbf"
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.leftPipeArrow, duration: duration.leftPipeArrow }}
    />
    <motion.line x1="147" y1="386" x2="182" y2="386" stroke="#37acbf" strokeWidth={1}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.leftPipeArrow, duration: duration.leftPipeArrow }}
    />
    {showRightWater && <Water width={248} height={346} x={370} y={51} leftOrRight={false} />}
    {/* 右外圈 */}
    <motion.rect width="248" height="346" x="369" y="50" rx="20" stroke="#ed7d31" strokeWidth={2} fill="transparent"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.3) 2px 2px 2px)"}}
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ delay: timePoints.rightOutLine, duration: duration.rightOutLine }}
    />
    {/* 右内圈 */}
    <motion.rect width="204" height="302" x="391" y="72" rx="8" stroke="#ed7d31" strokeWidth={2} fill="transparent"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.3) 2px 2px 2px)"}}
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ delay: timePoints.rightInnerLine, duration: duration.rightInnerLine }}
    />
    {/* 上大箭头 */}
    <clipPath id="largeUpArrowMask">
      <rect x="330" y="10" width="70" height="108" />
    </clipPath>
    <motion.rect width="288" height="386" x="347" y="28" rx="34" stroke="#bfbfbf" strokeWidth="16" fill="transparent"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.largeUpArrow, duration: duration.largeUpArrow }}
      clipPath="url(#largeUpArrowMask)"
    />
    <motion.polygon points="436,28 398,14 398,42" fill="#bfbfbf"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.largeUpArrow, duration: duration.largeUpArrow }}
    />
    <motion.text x="440" y="36" color="#bfbfbf" style={{ fontSize: 20, fontWeight: 300}}
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.largeUpArrowText, duration: duration.largeUpArrowText }}
    >充电：氧气放出</motion.text>
    {/* 下大箭头 */}
    <clipPath id="largeDownArrowMask">
      <rect x="330" y="370" width="100" height="108" />
    </clipPath>
    <motion.rect width="288" height="386" x="347" y="28" rx="34" stroke="#bfbfbf" strokeWidth="16" fill="transparent"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.largeDownArrow, duration: duration.largeDownArrow }}
      clipPath="url(#largeDownArrowMask)"
    />
    <motion.polygon points="347,332 333,370 361,370" fill="#bfbfbf"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.largeDownArrow, duration: duration.largeDownArrow }}
    />
    <motion.text x="440" y="422" color="#bfbfbf" style={{ fontSize: 20, fontWeight: 300}}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.largeDownArrowText, duration: duration.largeDownArrowText }}
    >放电：空气进入</motion.text>
    {/* 上箭头 */}
    <motion.polygon points="474,62 482,66 482,58" fill="#ed7d31"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.rightPipeArrow, duration: duration.rightPipeArrow }}
    />
    <motion.line x1="481" y1="62" x2="516" y2="62" stroke="#ed7d31" strokeWidth={1}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.rightPipeArrow, duration: duration.rightPipeArrow }}
    />
    {/* 下箭头 */}
    <motion.polygon points="525,386 517,390 517,382" fill="#ed7d31"
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.rightPipeArrow, duration: duration.rightPipeArrow }}
    />
    <motion.line x1="481" y1="386" x2="516" y2="386" stroke="#ed7d31" strokeWidth={1}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.rightPipeArrow, duration: duration.rightPipeArrow }}
    />
    {/* 绿底 */}
    <motion.rect width="210" height="210" x="220" y="118" fill="#a9d18f"
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: timePoints.powerUnitBg, duration: duration.powerUnitBg }}
    />
    <motion.text x="260" y="70" style={{fontSize: 32, fontWeight: 300}}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: hideCenterText ? 0 : 1, y: hideCenterText ? 10 : 0 }}
      transition={{ delay: hideCenterText ? 0 : timePoints.powerUnitText, duration: duration.powerUnitText }}
    >功率单元</motion.text>
    {/* 左青底 */}
    <motion.rect width="100" height="210" x="220" y="118" fill="#37acbf"
      initial={{ opacity: 0, scaleX: 0, x: -50 }}
      animate={{ opacity: 1, scaleX: 1, x: 0 }}
      transition={{ delay: timePoints.powerUnitLeftBg, duration: duration.powerUnitLeftBg }}
    />
    <motion.text x="271" y="166" fill="#fff"
      style={{fontSize: 24, fontWeight: 300, writingMode: 'vertical-lr', letterSpacing: 5}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.powerUnitLeftText, duration: duration.powerUnitLeftText }}
    >金属电极</motion.text>
    {/* 右橙底 */}
    <motion.rect width="100" height="210" x="330" y="118" fill="#ed7d31"
      initial={{ opacity: 0, scaleX: 0, x: 50 }}
      animate={{ opacity: 1, scaleX: 1, x: 0 }}
      transition={{ delay: timePoints.powerUnitRightBg, duration: duration.powerUnitRightBg }}
    />
    <motion.g
      initial={{ opacity: 1 }}
      whileHover={{ opacity: 0 }}
    >
      <motion.image href={electrode} width="100" height="210" x="330" y="118"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: timePoints.powerUnitRightImage, duration: duration.powerUnitRightImage }}
      />
    </motion.g>
    <motion.text x="471" y="166" fill="#000"
      style={{fontSize: 24, fontWeight: 300, writingMode: 'vertical-lr', letterSpacing: 5}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.powerUnitRightText, duration: duration.powerUnitRightText }}
    >空气电极</motion.text>
    {/* 左侧边 */}
    <motion.rect width="15" height="245" x="205" y="103" fill="#bfbfbf"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.35) -3px 0px 2px)"}}
      initial={{ opacity: 0, x: -30 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.leftBar, duration: duration.leftBar }}
    />
    {/* 右侧边 */}
    <motion.rect width="15" height="245" x="430" y="103" fill="#bfbfbf"
      style={{filter: "drop-shadow(rgba(0, 0, 0, 0.35) 3px 0px 2px)"}}
      initial={{ opacity: 0, x: 30 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.rightBar, duration: duration.rightBar }}
    />
    <motion.g>
      {/* 金属罐 */}
      {/* <motion.rect width="108" height="188" x="29" y="129" fill="#37acbf" */}
      <motion.rect width="140" height="190" x="-24" y="128" fill="#37acbf"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: timePoints.metalBucket, duration: duration.metalBucket }}
      />
      {/* 金属罐上边 */}
      <motion.rect width="140" height="36" x="-24" y="128" fill="url(#metal)"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: timePoints.metalBucketTop, duration: duration.metalBucketTop }}
      />
      {/* 金属罐下边 */}
      <motion.rect width="140" height="36" x="-24" y="282" fill="url(#metal)"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: timePoints.metalBucketBottom, duration: duration.metalBucketBottom }}
      />
      <motion.text x="18" y="231" fill="#fff" style={{fontSize: 20, fontWeight: 300}}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: timePoints.metalBucketText, duration: duration.metalBucketText }}
      >金属罐</motion.text>
    </motion.g>
    <motion.text x="-24" y="70" style={{fontSize: 32, fontWeight: 300}}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: hideLeftText ? 0 : 1, y: hideLeftText ? 10 : 0 }}
      transition={{ delay: hideLeftText ? 0 : timePoints.leftContainerText, duration: duration.leftContainerText }}
    >容量单元</motion.text>
    {/* 双氧水容器 */}
    <motion.rect id="waterContainer" width="140" height="190" x="535" y="128" fill="#ed7d31"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.waterBucket, duration: duration.waterBucket }}
    />
    {/* 双氧水容器上边 */}
    <motion.rect width="140" height="8" x="535" y="128" fill="#bfbfbf"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.waterBucketTop, duration: duration.waterBucketTop }}
    />
    {/* 双氧水容器下边 */}
    <motion.rect width="140" height="8" x="535" y="310" fill="#bfbfbf"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: timePoints.waterBucketBottom, duration: duration.waterBucketBottom }}
    />
    <motion.text x="535" y="70" style={{fontSize: 32, fontWeight: 300}}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: hideRightText ? 0 : 1, y: hideRightText ? 10 : 0 }}
      transition={{ delay: hideRightText ? 0 : timePoints.rightContainerText, duration: duration.rightContainerText }}
    >容量单元</motion.text>
    <motion.text fill="#fff" style={{fontSize: 20, fontWeight: 300}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: timePoints.waterBucketText, duration: duration.waterBucketText }}
    >
      <tspan x="578" y="218">双氧水</tspan>
      <tspan x="578" y="244">溶液罐</tspan>
    </motion.text>
  </>);
}

export default Battery;
