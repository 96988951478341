import React from 'react';
import waterCyan from '../images/wave-cyan.svg';
import waterOrange from '../images/wave-orange.svg';
import './water.less'; 

function Water({ leftOrRight, ...rest }: {
  height: number,
  width: number,
  x: number,
  y: number,
  /**
   * true for left, false for right
   */
  leftOrRight: boolean,
}) {
  const waveId = leftOrRight ? 'waveOrange' : 'waveCyan';
  const wave2Id = leftOrRight ? 'wave2Orange' : 'wave2Cyan';
  return (<svg {...rest} viewBox="0 0 248 346">
    <defs>
      <image id={waveId} href={leftOrRight ? waterCyan : waterOrange} height="100%" />
      <use id={wave2Id} xlinkHref={`#${waveId}`} />
    </defs>
    <clipPath id="pipe">
      <path
        className="pipe"
        d="M228,0.5 C233.384776,0.5 238.259776,2.68261184 241.788582,6.21141777 C245.317388,9.74022369 247.5,14.6152237 247.5,20 L247.5,326 C247.5,331.384776 245.317388,336.259776 241.788582,339.788582 C238.259776,343.317388 233.384776,345.5 228,345.5 L20,345.5 C14.6152237,345.5 9.74022369,343.317388 6.21141777,339.788582 C2.68261184,336.259776 0.5,331.384776 0.5,326 L0.5,20 C0.5,14.6152237 2.68261184,9.74022369 6.21141777,6.21141777 C9.74022369,2.68261184 14.6152237,0.5 20,0.5 L228,0.5 Z M218,21.5 L30,21.5 C27.6527898,21.5 25.5277898,22.4513949 23.9895924,23.9895924 C22.4513949,25.5277898 21.5,27.6527898 21.5,30 L21.5,316 C21.5,318.34721 22.4513949,320.47221 23.9895924,322.010408 C25.5277898,323.548605 27.6527898,324.5 30,324.5 L218,324.5 C220.34721,324.5 222.47221,323.548605 224.010408,322.010408 C225.548605,320.47221 226.5,318.34721 226.5,316 L226.5,30 C226.5,27.6527898 225.548605,25.5277898 224.010408,23.9895924 C222.47221,22.4513949 220.34721,21.5 218,21.5 Z"
      />
    </clipPath>
    <use className="wave-result" xlinkHref={`#${wave2Id}`} width='100%' height="100%" clipPath="url(#pipe)" />
  </svg>);
}

export default Water;
