import { Center, Image, Title } from "@mantine/core";
import React from "react";
import BatteryAnimation from "../components/batteryAnimation";
import powerGrid from "../videos/power-grid.mp4";
import p2x from '../images/p2x.jpg';
import lowerCost from '../images/lower-cost.svg';
import safe from '../images/safe.svg';
import efficient from '../images/efficient.svg';
import yingde from '../images/yingde.svg';
import yingdeInverse from '../images/yingde-inverse.svg';
import syensqo from '../images/syensqo.svg';
import syensqoInverse from '../images/syensqo-inverse.svg';
import shenergy from '../images/shenergy.svg';
import shenergyInverse from '../images/shenergy-inverse.svg';
import { Carousel } from "@mantine/carousel";
import './index.less';
import Container from "../components/container";
import { useMediaQuery } from '@mantine/hooks';
import SEO from "../components/seo";

const customers = [
  { normal: yingde, inverse: yingdeInverse, },
  { normal: syensqo, inverse: syensqoInverse, },
  { normal: shenergy, inverse: shenergyInverse, },
];

const IndexPage = () => {
  const hideP2xTitleBr = useMediaQuery('(max-width: 460px)');
  const sliderSm = useMediaQuery('(max-width: 768px)');

  return <div className="home">
    <section className="bg">
      <video src={powerGrid} autoPlay loop muted></video>
      <div className="text-tag">我们用新型电化学技术，让绿色电力重塑传统工业与电网！</div>
    </section>
    <section className="p2x">
      <Title className="title" order={1} id="p2x">
        我们通过电力多元转化技术（Power-to-X）, 投身于{!hideP2xTitleBr && <br />}传统化学品低碳生产（Power-to-Chemicals）与长时储能领域
      </Title>
      <Image src={p2x} fit="cover"/>
    </section>
    <section className="device">
      <div>
        <Title className="title" order={1}>颠覆性创新-大型电化学现场生产双氧水设备</Title>
        <Title className="sub-title" order={2}>环保绿色，仅消耗空气中的氧气、水和电</Title>
      </div>
      <div className="container">
        <Container />
      </div>
    </section>
    <section className="battery">
      <Title className="title" order={1}>领先的金属液流空气电池</Title>
      <Title className="sub-title" order={2}>电网级长时储能</Title>
      <div className="container">
        <div className="animation-wrapper">
          <div className="animation">
            <BatteryAnimation />
          </div>
        </div>
        <div className="item-wrapper">
          <div className="advantage-item">
            <img src={lowerCost} />
            <Title order={3}>低成本</Title>
            <p>金属空气电池只使用极其常见的金属材料，长时储能的容量成本为锂离子的十分之一。</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="advantage-item">
            <img src={safe} />
            <Title order={3}>安全性</Title>
            <p>金属空气电池在常温、常压条件下运行，没有燃烧介质，也没有任何爆炸风险。</p>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="advantage-item">
            <img src={efficient} />
            <Title order={3}>高效率</Title>
            <p>相比传统金属空气电池，橙氧创新金属空气电池会达到非常高的储能循环效率。</p>
          </div>
        </div>
      </div>
    </section>
    <section className="customers">
      <Title className="title" order={1}>我们的创新伙伴</Title>
      <Title className="sub-title" order={2}>在工业及能源领域，我们与我们的伙伴展开了深入合作，只为创新投入</Title>
      <Carousel
        slideSize="33.3333%"
        initialSlide={1}
        slidesToScroll={1}
        align="center"
        speed={8}
        withControls={!sliderSm}
        withIndicators
        loop
      >
        {customers.map(({normal, inverse}, index) => (<Carousel.Slide className="customer-logo" key={index}>
          <Center>
            <div className="image-container">
              <img className="inverse" src={inverse} />
              <img className="normal" src={normal} />
            </div>
          </Center>
        </Carousel.Slide>))}
      </Carousel>
    </section>
  </div>;
}

export default IndexPage

export const Head = () => (<SEO />)
