import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import Battery from './battery';

function BatteryAnimation() {
  const [hide, setHide] = React.useState<boolean>(false);

  const animation = useMemo(() => (
    <Battery replay={() => {
      setHide(true);
      setTimeout(() => {
        setHide(false);
      }, 0);
    }} />
  ), []);

  return (<motion.svg
    viewBox="-24 0 700 450"
    initial="hidden"
    animate="visible"
  >
    <defs>
      <pattern id="metal1" width="2" height="2">
        <rect width="1" height="1" x="0" y="0" fill="#7c7c7c"/>
        <rect width="1" height="1" x="0" y="1" fill="#dedede"/>
        <rect width="1" height="1" x="1" y="0" fill="#dedede"/>
        <rect width="1" height="1" x="1" y="1" fill="#7c7c7c"/>
      </pattern>
      <pattern id="metal2" width="2" height="2">
        <rect width="1" height="1" x="0" y="0" fill="#ffffff"/>
        <rect width="1" height="1" x="0" y="1" fill="#dedede"/>
        <rect width="1" height="1" x="1" y="0" fill="#dedede"/>
        <rect width="1" height="1" x="1" y="1" fill="#7c7c7c"/>
      </pattern>
      <pattern id="metal" width="4" height="4" patternUnits="userSpaceOnUse">
        <rect width="2" height="2" x="0" y="0" fill="url(#metal1)" />
        <rect width="2" height="2" x="0" y="2" fill="url(#metal2)" />
        <rect width="2" height="2" x="2" y="0" fill="url(#metal2)" />
        <rect width="2" height="2" x="2" y="2" fill="url(#metal1)" />
      </pattern>
    </defs>
    {hide ? <></> : animation};
  </motion.svg>);
}

export default BatteryAnimation;
