import React from 'react';
import { SVGAttributes } from 'react';

function Marker({ color, x, y, width, height }: SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="-50 -50 100 100" x={x} y={y} width={width} height={height}>
      <circle x="0" y="0" r="47" fill='#ffffffc0' stroke={color} strokeWidth={3} />
      <circle x="0" y="0" r="36" fill={color} />
      <line x1="-24" y1="0" x2="24" y2="0" stroke="#fff" strokeWidth={6} />
      <line x1="0" y1="-24" x2="0" y2="24" stroke="#fff" strokeWidth={6} />
    </svg>
  );
}

export default Marker;
